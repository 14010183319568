import SharedFunctions from './shared_functions';
require('bootstrap/js/dist/modal');

$(document).ready(function () {

    let $email = $("#email");

    // trigger the gdpr check on page load when email is pre populated
    SharedFunctions.form_display_gdpr_checkbox($email.val(), $("#opt_out_email"), $(".dont-send-me-checkbox"), $("#skip_opt_out_email"));

    // trigger the required tanker on page load when email is pre populated
    SharedFunctions.form_check_required_tanker_type($email.val(), $("#theTanker"));

    $oilQuoteForm.submit(function () {
        let form_valid = true;
        let message = '';
        let error_message = '';
        let $email = $oilQuoteForm.find("input#email");
        let email = $email.val();
        let $postcode = $oilQuoteForm.find("input#postcode");
        let $litres = $oilQuoteForm.find("input#volume");

        const $submitButton =  $(this).find("button[type='submit']");
        let buttonText = $submitButton.text();
        $submitButton.html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...');

        // Check if the button is already disabled
        if ($submitButton.prop("disabled")) {
            e.preventDefault();
            return false; // Prevent further submission
        }

        // Disable the button to prevent double submission
        $submitButton.prop("disabled", true);

        removeInvalidState($email, $postcode, $litres);

        let $shownDomesticCommercialModal = $("#shown-domestic-commercial-popup");

        // Validate email
        let email_check = SharedFunctions.form_email_valid(email, form_valid, message);
        form_valid = email_check[0]
        message = email_check[1]

        if ($versionForm === 2) {
            if (!form_valid) {
                $email.addClass('is-invalid');
                $email.next().html(message);
            }
        }

        // strip excess whitespace from postcode val first
        $postcode.val($postcode.val().trim());

        // Validate the postcode field
        let postcode_check = SharedFunctions.form_postcode_valid($postcode, form_valid, message)
        form_valid = postcode_check[0]
        message = postcode_check[1]

        if ($versionForm === 2) {
            if (!postcode_check[0] && postcode_check[2]) {
                $postcode.addClass('is-invalid');
                $postcode.next().html('<li>' + postcode_check[2] + '</li>')
            }
        }

        // check min litres by postcode
        if ($postcode.val().length > 0) {
            let min_litres_required = min_litres;
            let postcode_area = $postcode.val().substr(0, 2);

            if (postcode_area === 'BT') {
                min_litres_required = bt_min_litres;
            }
            if (parseInt($litres.val()) < min_litres_required) {
                form_valid = false;
                error_message = 'Please note the minimum order quantity is ' + min_litres_required + ' litres.';
                message += '<li>' + error_message + '</li>';

                if ($versionForm === 2) {
                    $litres.addClass('is-invalid');
                    $litres.next().html('<li>' + error_message +'</li>')
                }

            }
        } else {
            form_valid = false;
            error_message = 'Please specify your required order volume.';
            message += '<li>' + error_message + '</li>';

            if ($versionForm === 2) {
                $litres.addClass('is-invalid');
                $litres.next().html('<li>' + error_message + '</li>')
            }
        }

        if (!form_valid) {
            $oilQuoteFormToast.append('<div class="toast-header bg-danger text-white"><strong class="mr-auto">Error</strong><small class="text-white">Just now</small><button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close"><span aria-hidden="true">&times;</span></button></div><div class="toast-body bg-white"><ul>' + message + '</ul></div></div>');
            $oilQuoteFormToast.toast('show');
            $oilQuoteFormToast.on('hidden.bs.toast', function () {
                $oilQuoteFormToast.html('');
            });

            // re-enable the submit button
            $submitButton.prop("disabled", false);
        }

        // present domestic or commercial popup if volume 2300+
        if (form_valid && $litres.val() >= commercial_min_litres && $shownDomesticCommercialModal.val() === 'n') {
            form_valid = false;

            // trigger popup
            $('#domesticOrCommercialModal').modal('show');

            // re-enable the submit button
            $submitButton.prop("disabled", false);
        }

        return form_valid;
    });

    function removeInvalidState(email, postcode, litres) {
        email.removeClass('is-invalid');
        postcode.removeClass('is-invalid');
        litres.removeClass('is-invalid');
    }

    $email.on("focusout", function () {
        let $containing_div = $(".dont-send-me-checkbox");
        let $checkbox = $("#opt_out_email");
        let $skipInput = $("#skip_opt_out_email");
        let email = $(this).val();

        SharedFunctions.form_display_gdpr_checkbox(email, $checkbox, $containing_div, $skipInput);
        SharedFunctions.form_check_required_tanker_type(email);
    });

    // Domestic commercial Modal
    $("#usage-modal-domestic").on('click', function () {
        $("#oil-usage-type").val('domestic');
        $("#shown-domestic-commercial-popup").val('y');
        $oilQuoteForm.submit();
    });

    $("#usage-modal-commercial").on('click', function () {
        $("#oil-usage-type").val('commercial');
        $("#shown-domestic-commercial-popup").val('y');
        $oilQuoteForm.submit();
    });

});
